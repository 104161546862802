import React from 'react'
import ReactDOM from 'react-dom'
import theme from './styling/theme'
import './styling/index.css'
import './styling/fonts.css'
import { ThemeProvider } from '@material-ui/core'
import { App } from './components/App'
import { HashRouter } from 'react-router-dom'
// import i18n from './i18n'
// import './i18n'
// import { Provider } from 'react-redux'
// import { store } from './store'

// if (!i18n.language && navigator && navigator.language) {
//   i18n.changeLanguage(
//     navigator.language.substr(0, 2) === 'ja' ? navigator.language : 'en-US'
//   )
// } else {
//   i18n.changeLanguage('en-US')
// }

ReactDOM.render(
    // <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <App />
        </HashRouter>
      </ThemeProvider>,
    // </Provider>,
    document.getElementById('root')
  )