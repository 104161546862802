import React, { useState } from 'react'
import { AppBar, Hidden, IconButton, makeStyles, SwipeableDrawer, Toolbar  } from '@material-ui/core'
import { spacing } from '../../styling'
import theme from '../../styling/theme'
import { Icon } from '../Icon'
import { ListItemMenuBar } from './ListItemMenuBar'
import { Logo } from '../Logo'
import { Avatar } from '../Avatar'

const TABS = [
  'Solutions',
  'Pricing',
  'Download',
  'Resources',
  'Support'
]

export const NavBar = () => {
  const css = useStyles()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }
 
  return (
    <>
        <AppBar position="fixed" className={css.appBar}>
          <Toolbar className={css.toolbar}>
            <Hidden xsDown>
              <div className={css.logo}>
                  <Logo white={true} width={120} marginTop={3} marginLeft={5}/>
              </div>
            </Hidden>
              <IconButton className={css.menuButton} onClick={handleOpen}>
                <Icon name="bars"></Icon>
              </IconButton>
            <div className={css.menu}>
                <ListItemMenuBar open={open} handleOpen={handleOpen}/>
            </div>
            <div>
              <Avatar email={'mauricio@remote.it'} button  /> 
            </div>
          </Toolbar>
        </AppBar>
        {/* <div className={css.offset}></div> */}
    </>
  )
}

const useStyles = makeStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      width:'100%',
      marginBottom: '25%',
      backgroundColor: 'white',
      boxShadow: 'none',
      margin:0,
    },
    toolbar: {
      backgroundColor: 'rgb(1 10 68)',
      // marginBottom: 50,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
      color: 'white'
    },
    offset: theme.mixins.toolbar,
    menu : {
      flexGrow: 1,
    },
    logo: {
      marginBottom: spacing.md,
      marginLeft: `5%`,
      marginRight: `10%`
    }
  })
