import React from 'react'
import { Router } from '../../routers/Router'

export const App: React.FC = () => {

  return (
    <>
     <Router />
    </>
  )
}
