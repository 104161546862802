import React, { useState } from 'react'
// import { ApplicationState } from '../../store'
// import { Button } from '../Button'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../components/Alert'
import { Input } from '../../components/Input'
import { TextBlock } from '../../components/TextBlock'
import { PasswordStrengthInput } from '../../components/PasswordStrengthInput'
import { Section } from '../../components/Section'
import { Button, Typography } from '@material-ui/core'

interface Props {
  changePassword: (password: string) => Promise<void>
}
// export type ChangePasswordProps = Props &
//   ReturnType<typeof mapState> &
//   ReturnType<typeof mapDispatch>

// const mapState = (state: ApplicationState) => ({
//   AWSUser: state.auth.AWSUser,
// })

const mapDispatch = (dispatch: any) => ({
  changePassword: dispatch.auth.changePassword,
})

export const ChangePassword = () => {
  const [error, setError] = useState<string | null>(null)
  const [message, setMessage] = useState<string | null>(null)
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  

  const setPasswordValidation = (password: React.SetStateAction<string>, valid: boolean | ((prevState: boolean) => boolean)) => {
    setPassword(password)
    setIsValidPassword(valid)
    setDisabled(valid && currentPassword !== '')
  }
  const evaluateCurrentPassword = (e: { target: { value: React.SetStateAction<string> } }) => {
    setCurrentPassword(e.target.value)
    setDisabled(isValidPassword && e.target.value !== '')
  }
  const updatePassword = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setMessage(null)
    setError(null)
    setLoading(true)
    // changePassword({ currentPassword: currentPassword, password: password })
    //   .then(() => {
    //     setMessage(t('pages.change-password.success'))
    //   })
    //   .catch((error: { code: any }) => {
    //     console.error(error)
    //     setError(t(`pages.change-password.errors.${error.code}`))
    //   })
    //   .finally(() => {
    //     setLoading(false)
    //   })
  }
  return (
    <Section title="Change Password"
    //{t('pages.change-password.title')} 
    >
      {/* {AWSUser && AWSUser.authProvider === 'Google' ? ( */}
      {false ? (
        <TextBlock>{t('pages.change-password.is-google-user')}</TextBlock>
      ) : (
        <form style={{ maxWidth: '360px' }} onSubmit={updatePassword}>
          {error && (
            <Alert
              className="my-md"
              type="danger"
              stayOpen={true}
            >
              {error}
            </Alert>
          )}
          {message && (
            <Alert
              className="my-md"
              type="info"
              stayOpen={true}
            >
              {message}
            </Alert>
          )}
          <div className="my-lg">
            <Typography variant="subtitle2" gutterBottom>
              Current Password
            </Typography>
            <Input
              block
              type="password"
              //onChange={(e: { target: { value: React.SetStateAction<string> } }) => evaluateCurrentPassword(e)}
              placeholder="Enter current password"
               //{t('global.user.password-current-placeholder')}
              autoComplete="current-password"
            />
          </div>
          <div>
            <Typography variant="subtitle2" gutterBottom>
              New Password
            </Typography>
            <PasswordStrengthInput
              onChange={(password: React.SetStateAction<string>, valid: boolean | ((prevState: boolean) => boolean)) =>
                setPasswordValidation(password, valid)
              }
              isNewPassword={true}
            />
          </div>
          <div className="mt-lg">
            <Button variant="contained" color="primary" type="submit" >
              {/* {t('pages.change-password.update-password-button')} */}
              SAVE
            </Button>
            <Button color="default">
              CANCEL
            </Button>
            
          </div>
        </form>
      )}
    </Section>
  )
}
