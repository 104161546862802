import { Typography } from '@material-ui/core'
import React from 'react'
// import { Icon } from '../Icon'

export const Section = ({
    title,
    // icon,
    children,
  }: {
    title: string | React.ReactNode
    // icon: string
    children: React.ReactNode
  }): JSX.Element => {
    return (
      <section
        className="df p-xl bb bc-gray-lightest"
        style={{ maxWidth: '100%' }}
      >
        {/* <div className="mr-xl">
          <div className="rad-100 bg-gray-lightest">
            <Icon name={icon} color="gray" className="txt-xxl p-lg" />
          </div>
        </div> */}
        <div className="txt-md w-100">
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
          {children}
        </div>
      </section>
    )
  }

