import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { Overview } from '../pages/OverView'
import { Container } from '../components/Container'
import { ChangePassword } from '../pages/ChangePassword'
import { Profile } from '../pages/Profile'
import { Plans } from '../pages/Plans'
import { OtherPlans } from '../pages/OtherPlans'

export const Router: React.FC = ({  }) => {
  
  const history = useHistory()
  
  return (
    <Switch>

      <Route path="/overview">
        <Container>
          <Overview />
        </Container>
      </Route>

      <Route path="/changePassword">
        <Container>
          <ChangePassword />
        </Container>
      </Route>

      <Route path="/profile">
        <Container>
          <Profile/>
        </Container>
      </Route>

      <Route path="/plans">
        <Container>
          <Plans/>
        </Container>
      </Route>
      
      <Route path="/otherPlans">
        <Container>
          <OtherPlans/>
        </Container>
      </Route>

      {/* Default */}
      <Route path="/">
        <Redirect to="/overview" />
      </Route>

    </Switch>
  )
}
