
import { useLocation } from 'react-router-dom'

interface INavigationHook {
  LeftMenuItems: INavigation[]
}
export function useNavigation(): INavigationHook {

  const location = useLocation()
 
  const LeftMenuItems: INavigation[] = [
    
    { path: "/overview",label: 'Overview', icon : 'home'},
    { path: "/profile",label: 'Profile', icon: 'head-side'},
    { path: "/changePassword",label: 'Change Password', icon: 'key'},
    { path: "/plans",label: 'remote.it Plans', icon: 'book'},
    { path: "/otherPlans",label: 'Other Plans', icon: 'th-large'},
    { path: "/notificationSettings",label: 'Notification Setting', icon: 'bell'},
    { path: "/transactions",label: 'Transactions', icon: 'file-alt'},
    { path: "/accessKeys",label: 'Access Keys', icon: 'puzzle-piece'},
    { path: "/remoteWeb",label: 'remote.it for Web', icon: 'browser'},
  ]

  return { LeftMenuItems }
}
