import React, { useState } from 'react'
import './PasswordStrengthInput.css'
import { Input } from '../Input'
import { useTranslation } from 'react-i18next'
import zxcvbn from 'zxcvbn'
import { Typography } from '@material-ui/core'

export interface Props {
  isNewPassword?: boolean
  onChange: (password: string, isValid: boolean) => void
}

export function PasswordStrengthInput({ isNewPassword, onChange }: Props) {
  const { t } = useTranslation()
  const PASSWORD_MIN_LENGTH = Number(process.env.PASSWORD_MIN_LENGTH)
  const PASSWORD_MAX_LENGTH = Number(process.env.PASSWORD_MAX_LENGTH)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [valid, setValid] = useState<boolean>(false)
  const [tooShort, setTooShort] = useState<boolean>(true)
  const [tooLong, setTooLong] = useState<boolean>(false)
  const [hasMatch, setHasMatch] = useState<boolean>(false)

  const checkTestedResult = (pass: string) => {
    return zxcvbn(pass).score
  }

  const checkTooShort = (pass: string) => {
    const value = (pass && pass.length) < PASSWORD_MIN_LENGTH
    setTooShort(value)
    return value
  }
  const checkTooLong = (pass: string) => {
    const value = (pass && pass.length) > PASSWORD_MAX_LENGTH
    setTooLong(value)
    return value
  }
 
  
  const checkMatches = (pass: string, passConfirm: string) => {
    const value = pass !== '' && passConfirm !== '' && pass === passConfirm
    setHasMatch(value)
    return value
  }
  const checkPasswordConfirmation = (e: any) => {
    setPasswordConfirmation(e.target.value)
    const isValid = checkValid(password)
    const isMatching = checkMatches(password, e.target.value)
    sendChange(password, isValid && isMatching)
  }
  const checkPassword = (e: any) => {
    setPassword(e.target.value)
    const isValid = checkValid(e.target.value)
    const isMatching = checkMatches(e.target.value, passwordConfirmation)
    sendChange(password, isValid && isMatching)
  }
  const createPasswordStrengthLabel = (score: any) => {
    switch (score) {
      case 0:
        return t('pages.forgot-password-verify.password-strength-score.0')
      case 1:
        return t('pages.forgot-password-verify.password-strength-score.1')
      case 2:
        return t('pages.forgot-password-verify.password-strength-score.2')
      case 3:
        return t('pages.forgot-password-verify.password-strength-score.3')
      case 4:
        return t('pages.forgot-password-verify.password-strength-score.4')
      default:
        return t('pages.forgot-password-verify.password-strength-score.0')
    }
  }

  const checkValid = (pass: string) => {
    const isValid = !checkTooShort(pass) && !checkTooLong(pass)
    setValid(isValid)
    return isValid
  }

  const sendChange = (pass: string, isValid: boolean) => {
    onChange(pass, isValid)
  }
  return (
    <>
      <div className="mb-lg">
        {/* <TextBlock> */}
          {/* {t('pages.forgot-password-verify.password-rules-reduced', {
            min_length: PASSWORD_MIN_LENGTH,
            max_length: PASSWORD_MAX_LENGTH,
          })} */}
          
        {/* </TextBlock> */}
        <Typography variant="caption" display="block" gutterBottom>Passwords must be 7-64 characters in length.</Typography>
        {((password !== '' && !valid) ||
          (passwordConfirmation != '' && !hasMatch)) && (
          <div className="danger mt-sm">
            {t('pages.forgot-password-verify.password-error.invalid')}

            <ul>
              {tooShort && (
                <li>
                  {t('pages.forgot-password-verify.password-error.too-short', {
                    min_length: PASSWORD_MIN_LENGTH,
                  })}
                </li>
              )}
              {tooLong && (
                <li>
                  {t('pages.forgot-password-verify.password-error.too-long', {
                    max_length: PASSWORD_MAX_LENGTH,
                  })}
                </li>
              )}
             
              {passwordConfirmation != '' && !hasMatch && (
                <li>
                  {t(
                    'pages.forgot-password-verify.password-error.passwords-do-not-match'
                  )}
                </li>
              )}
            </ul>
          </div>
        )}
        <div className="meter">
          <Input
            block
            type="password"
            autoComplete="new-password"
            className="txt-md px-md py-sm lh-md"
            onChange={e => checkPassword(e)}
            aria-label={t('global.user.password')}
            placeholder="Enter new password"
            // {t(
            //   `global.user.${isNewPassword ? 'new-' : ''}password-placeholder`
            // )}
          />
          <div>
            {valid && (
              <>
                <progress
                  value={checkTestedResult(password)}
                  max="4"
                  className={`password-strength-meter strength-${checkTestedResult(
                    password
                  )}`}
                />
                <label className="password-strength-meter-label">
                  {t('pages.forgot-password-verify.password-strength-html', {
                    strength: createPasswordStrengthLabel(
                      checkTestedResult(password)
                    ),
                  })}
                </label>
              </>
            )}
          </div>
        </div>
      </div>
      <Input
        block
        type="password"
        autoComplete="new-password"
        onChange={e => checkPasswordConfirmation(e)}
        placeholder="Confirm new password"
        // {t(
        //   `global.user.${
        //     isNewPassword ? 'new-' : ''
        //   }password-confirm-placeholder`
        // )}
        aria-label={t('global.user.password-confirm')}
      />
    </>
  )
}
